<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">Руководство</div>
        </div>

        <div class="container-shadow fill-white p-24 mb-24">
          <div class="lead" itemprop="rucovodstvo">
            <div class="lead__image">
              <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(https://exportedu.ru/images/photo_nikitina.jpg)'}"></div>
            </div>
            <div class="lead__content content_18">
              <div class="content_subtitle content_700 mb-24" itemprop="fio">Алисия Равилевна Никитина</div>
              <div class="lead__content-item mb-24">
                <div class="content_blue_light">Должность</div>
                <div class="lead__content-data" itemprop="post">Генеральный директор Школы экспорта РЭЦ</div>
              </div>
              <div class="lead__content-item">
                <div class="content_blue_light">Биографическая справка</div>
                <div class="lead__content-data">
                  <p>Закончила Государственный Педагогический Университет по специальности учитель английского и немецкого
                    языков, а также Финансовый Университет при Правительстве Российской Федерации по специальности
                    финансовый менеджмент. В Группу Российского экспортного центра пришла в 2011 году на позицию Заместителя
                    руководителя внешних коммуникаций Экспортного страхового агентства России (входит в Группу). С 31
                    декабря 2015 года - директор образовательного проекта АО "Российский экспортный центр", в 2016 году
                    назначена Генеральным директором АНО ДПО «Школа экспорта АО «Российский экспортный центр».</p>
                  <p>Ранее занимала позиции начальника отдела информационного обеспечения ВЭД Министерства Экономического
                    Развития РФ, начальника отдела брендинга Внешэкономбанка, директора по работе с клиентами BBDO
                    Group.</p>
                </div>
              </div>
            </div>
            <div class="lead__info">
              <div class="lead__content-item mb-24">
                <div class="content_blue_light">График работыа</div>
                <div class="lead__content-data">Пн. – Пт.: с 9:00 до 18:00</div>
              </div>
              <div class="lead__content-item">
                <div class="content_blue_light">Контактная информация</div>
                <div class="lead__content-data mb-1" itemprop="telephone">+7 (495) 937-47-47</div>
                <div class="lead__content-data mb-1"><a class="content_red content_underline" itemprop="email"
                                                        href="mailto:Nikitina@exportcenter.ru">Nikitina@exportcenter.ru</a>
                </div>
                <div class="lead__content-data">123610, Москва, Краснопресненская наб., 12,<br /> подъезд 9</div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-grid grid-2 grid-md-1 mb-5">
          <div class="container-grid__item">
            <div class="container-shadow fill-white p-24">
              <div class="lead" itemprop="rucovodstvoZam">
                <div class="lead__image">
                  <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(https://exportedu.ru/pic/setyaev.png)'}"></div>
                </div>
                <div class="lead__content content_18">
                  <div class="content_subtitle content_700 mb-24" itemprop="fio">Сетяев Василий Семенович</div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">Должность</div>
                    <div class="lead__content-data" itemprop="post">Директор по акселерации экспорта</div>
                  </div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">График работы</div>
                    <div class="lead__content-data">Пн. – Пт.: с 9:00 до 18:00</div>
                  </div>
                  <div class="lead__content-item">
                    <div class="content_blue_light">Контактная информация</div>
                    <div class="lead__content-data">
                      <div itemprop="telephone">+7 (495) 937-47-47</div>
                      <div><a class="content_red content_underline" itemprop="email"
                              href="mailto:tandreeva@exportcenter.ru">setyaev@exportcenter.ru</a>
                      </div>
                      <div>123610, Москва, Краснопресненская наб., 12, подъезд 9</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-grid__item">
            <div class="container-shadow fill-white p-24">
              <div class="lead" itemprop="rucovodstvoZam">
                <div class="lead__image">
                  <div class="avatar avatar_big container-shadow">
                    <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(https://exportedu.ru/images/photo_lyashenko.jpg)'}"></div>
                  </div>
                </div>
                <div class="lead__content content_18">
                  <div class="content_subtitle content_700 mb-24" itemprop="fio">Артур Владимирович Лященко</div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">Должность</div>
                    <div class="lead__content-data" itemprop="post">Директор по маркетингу</div>
                  </div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">График работы</div>
                    <div class="lead__content-data">Пн. – Пт.: с 9:00 до 18:00</div>
                  </div>
                  <div class="lead__content-item">
                    <div class="content_blue_light">Контактная информация</div>
                    <div class="lead__content-data">
                      <div itemprop="telephone">+7 (495) 937-47-47</div>
                      <div><a class="content_red content_underline" itemprop="email"
                              href="mailto:lyaschenko@exportcenter.ru">lyaschenko@exportcenter.ru</a>
                      </div>
                      <div>123610, Москва, Краснопресненская наб., 12, подъезд 9</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-grid__item">
            <div class="container-shadow fill-white p-24">
              <div class="lead" itemprop="rucovodstvoZam">
                <div class="lead__image">
                  <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(https://exportedu.ru/images/komarova.jpg)'}"></div>
                </div>
                <div class="lead__content content_18">
                  <div class="content_subtitle content_700 mb-24" itemprop="fio">Яна Анатольевна Комарова</div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">Должность</div>
                    <div class="lead__content-data" itemprop="post">Директор по работе с образовательными организациями</div>
                  </div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">График работы</div>
                    <div class="lead__content-data">Пн. – Пт.: с 9:00 до 18:00</div>
                  </div>
                  <div class="lead__content-item">
                    <div class="content_blue_light">Контактная информация</div>
                    <div class="lead__content-data">
                      <div itemprop="telephone">+7 (495) 937-47-47</div>
                      <div><a class="content_red content_underline" itemprop="email"
                              href="mailto:komarova@exportcenter.ru">komarova@exportcenter.ru</a>
                      </div>
                      <div>123610, Москва, Краснопресненская наб., 12, подъезд 9</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-grid__item">
            <div class="container-shadow fill-white p-24">
              <div class="lead" itemprop="rucovodstvoZam">
                <div class="lead__image">
                  <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(https://exportedu.ru/images/mutalib.jpg)'}"></div>
                </div>
                <div class="lead__content content_18">
                  <div class="content_subtitle content_700 mb-24" itemprop="fio">Муталиб Абдулгамидович Курахов</div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">Должность</div>
                    <div class="lead__content-data" itemprop="post">Директор по организации обучения</div>
                  </div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">График работы</div>
                    <div class="lead__content-data">Пн. – Пт.: с 9:00 до 18:00</div>
                  </div>
                  <div class="lead__content-item">
                    <div class="content_blue_light">Контактная информация</div>
                    <div class="lead__content-data">
                      <div itemprop="telephone">+7 (495) 937-47-47</div>
                      <div><a class="content_red content_underline" itemprop="email"
                              href="mailto:kurahov@exportcenter.ru">kurahov@exportcenter.ru</a>
                      </div>
                      <div>123610, Москва, Краснопресненская наб., 12, подъезд 9</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-grid__item">
            <div class="container-shadow fill-white p-24">
              <div class="lead" itemprop="rucovodstvoZam">
                <div class="lead__image">
                  <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(https://exportedu.ru/pic/tashbaeva.jpg)'}"></div>
                </div>
                <div class="lead__content content_18">
                  <div class="content_subtitle content_700 mb-24" itemprop="fio">Ирина Анваровна Ташбаева</div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">Должность</div>
                    <div class="lead__content-data" itemprop="post">Директор по методологии обучения</div>
                  </div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">График работы</div>
                    <div class="lead__content-data">Пн. – Пт.: с 9:00 до 18:00</div>
                  </div>
                  <div class="lead__content-item">
                    <div class="content_blue_light">Контактная информация</div>
                    <div class="lead__content-data">
                      <div itemprop="telephone">+7 (495) 937-47-47</div>
                      <div><a class="content_red content_underline" itemprop="email"
                              href="mailto:tashbaeva@exportcenter.ru">tashbaeva@exportcenter.ru</a>
                      </div>
                      <div>123610, Москва, Краснопресненская наб., 12, подъезд 9</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <document-list :documents="[{
            title: 'Функциональная структура Школы экспорта',
            link: '//exporteduru.servicecdn.ru/media/default/0002/89/ce75b81b977f2fb2ff5d55b53c2f24cc02a798a6.pdf'
          }]" />
        <div class="my-5 d-block d-md-none">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>
        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import CompanyNavigation from "../../components/CompanyNavigation";
import DocumentList from "@/components/DocumentList.vue";
export default {
  name: "InfoLeads",
  components: {DocumentList, CompanyNavigation, PageFooter},
  mounted() {
    this.$parent.init()
  }
}
</script>

<style lang="scss">
  .section__company {
    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-top: 7rem!important;
      &.no-margin {
        margin: -40px 0 20px!important;
      }
    }
    .content_18 {
      a {
        color: #ed1b2f;
        text-decoration: underline;
      }
    }
  }
</style>